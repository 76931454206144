import { useState, useEffect } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import DatePicker from "react-datepicker"
import { Modal, Table, Button } from "@bitcine/cs-theme"
import { getChannels } from "../../redux/channels/api"
import { playChannelsOnDevices } from "../../redux/devices/api"

import "react-datepicker/dist/react-datepicker.css"

const PlayChannelOnDevicesButton = ({ deviceIDs, getChannels, status, channels, playChannelsOnDevices, clearSelectedIDs }) => {
  const [modal, setModal] = useState({ show: false })
  const [startDate, setStartDate] = useState(null)
  const [selectedChannel, setSelectedChannel] = useState({})
  useEffect(() => {
    if (channels.length === 0) {
      getChannels()
    }
  }, [getChannels, channels])
  return (
    <>
      <div className='flex items-center'>
        <Button
          icon="schedule"
          className='mr2'
          disabled={deviceIDs.length === 0}
          onClick={() => setModal({ show: true, schedule: true })}>
          Schedule Channel
        </Button>
        <Button
          disabled={deviceIDs.length === 0}
          onClick={() => setModal({ show: true })}>
          Play Channel
        </Button>
      </div>
      {modal.show && <Modal open width={2} title={`${modal.schedule ? "Schedule" : "Play"} Channel`} onClose={() => setModal({ show: false })}>
        <div> 
          {modal.schedule && <div className='mb2'>
            <p>Select when you would like the channel to play.</p>
            <DatePicker className='cs-input' dateFormat="Pp" showTimeSelect selected={startDate} onChange={date => setStartDate(date)} />
          </div>}
          <p>Select which channel you would like to play on the selected device{deviceIDs.length === 1 ? '' : 's'}.</p>
          <Table
            className='my3'
            widths={["auto", 200]}
            header={{
              columns: [
                { text: "Label" },
                { text: "Status" },
              ],
            }}
            body={{
              data: channels,
              row: {
                compact: true,
                checkbox: {
                  checked: (data) => selectedChannel._id === data._id,
                  onChange: (data) => setSelectedChannel(data),
                },
                onClick: (e, data) => setSelectedChannel(data),
                render: [
                  data => data.label,
                  data => data.status,
                ]
              },
            }}/>
          <div className='flex items-center justify-end mt2'>
            <Button
              onClick={() => {
                playChannelsOnDevices({ channelIDs: [selectedChannel._id], deviceIDs, startDate })
                setModal({ show: false })
                clearSelectedIDs()
              }}>
              {modal.schedule ? "Schedule" : "Play"} {selectedChannel.label} on {deviceIDs.length} device{deviceIDs.length === 1 ? '' : 's'}
            </Button>
          </div>
        </div>
      </Modal>}
    </>
  )
}

const mapStateToProps = state => ({
  status: state.channels.status,
  channels: state.channels.list
})

const mapDispatchToProps = dispatch => ({
  getChannels: bindActionCreators(getChannels, dispatch),
  playChannelsOnDevices: bindActionCreators(playChannelsOnDevices, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayChannelOnDevicesButton))