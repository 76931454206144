import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import Channels from '../pages/Channels'
import Channel from '../pages/Channel'
import Devices from '../pages/Devices'
import Device from '../pages/Device'

const Routes = () =>
  <Switch>
    <Redirect exact path='/' to='channels'/>
    <Route exact path='/channels' component={Channels}/>
    <Route exact path='/channels/:channelID' component={Channel}/>
    <Route exact path='/devices' component={Devices}/>
    <Route exact path='/devices/:deviceID' component={Device}/>
  </Switch>

export default Routes