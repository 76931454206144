import {
  GET_DEVICES,
  GET_DEVICE,
  UPDATE_DEVICE,
  DELETE_DEVICE,
  PLAY_CHANNELS
} from "./types"

const initialState = {
  status: "PENDING",
  list: [],
  model: {}
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // case GET_DEVICES.PENDING:
    // case GET_DEVICE.PENDING:
    case DELETE_DEVICE.PENDING:
    case UPDATE_DEVICE.PENDING:
      return initialState
    case GET_DEVICES.FULFILLED:
    case DELETE_DEVICE.FULFILLED:
      return {
        ...state,
        status: "READY",
        list: action.payload
      }
    case GET_DEVICE.FULFILLED:
    case UPDATE_DEVICE.FULFILLED:
      return {
        ...state,
        status: "READY",
        model: action.payload
      }
    case PLAY_CHANNELS.PENDING:
      return {
        ...state,
        status: "PENDING"
      }
    case PLAY_CHANNELS.FULFILLED:
      return {
        ...state,
        status: "READY",
        ...Array.isArray(action.payload)
          ? {
            list: action.payload
          }
          : {
            model: action.payload
          }
      }
    default:
      return state
  }
}