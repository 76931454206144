import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Status } from '@bitcine/cs-theme'
import { authCheck } from '../../redux/auth/api'

const AuthenticationWrapper = ({ authCheck, status, children }) => {
  useEffect(() => {
    authCheck()
  }, [authCheck])
  return (
    <Status pending={status !== 'AUTHENTICATED'}>
      {status === 'AUTHENTICATED' && <>{children}</>}
    </Status>
  )
}


const mapStateToProps = state => ({
  status: state.auth.status
})

const mapDispatchToProps = dispatch => ({
  authCheck: bindActionCreators(authCheck, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper)