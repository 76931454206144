import { useState } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Modal, Button, ButtonDropdown } from "@bitcine/cs-theme"
import Select from "react-select"
import { createChannel } from "../../redux/channels/api"

const CreateChannelButton = ({ createChannel, channels, history }) => {

  const [showModal, setShowModal] = useState(false)
  const [showTimeshiftModal, setShowTimeshiftModal] = useState(false)
  const [timeshiftInMinutes, setTimeshift] = useState("")
  const [channelID, setChannelID] = useState(null)
  const [label, setLabel] = useState("")

  // Filter out other timeshifted channels
  const options = channels.filter(channel => !channel.parent_live_channel_id).map(channel => ({ value: channel._id, label: channel.label }))

  return (
    <>
      <ButtonDropdown
        button={{
          text: "Create Channel"
        }}
        dropdown={{
          content: [
            {
              text: "Create RTMP Channel",
              onClick: () => setShowModal(true)
            },
            {
              text: "Create Timeshift Channel",
              onClick: () => setShowTimeshiftModal(true)
            }
          ]
        }}/>
      {showModal && <Modal open title="Create New RTMP Channel" onClose={() => setShowModal(false)}>
        <div> 
          <p>Enter a descriptive label for this channel then click "Create Channel" below.</p>
          <input
            className='cs-input col-12'
            placeholder='Enter label here'
            value={label}
            onChange={e => setLabel(e.target.value)}/>
          <div className='flex items-center justify-end mt2'>
            <Button
              onClick={() => createChannel({ label }, res => {
                setShowModal(false)
                history.push(`channels/${res._id}`)
              })}>Create RTMP Channel</Button>
          </div>
        </div>
      </Modal>}
      {showTimeshiftModal && <Modal open title="Create New Timeshift Channel" onClose={() => setShowTimeshiftModal(false)}>
        <div> 
          <p>Select an existing channel, then set the timeshift value in minutes.</p>
          <Select
            options={options}
            value={options.find(opt => opt.value === channelID)}
            onChange={opt => setChannelID(opt.value)}/>
          <p className='mt2'>Enter a timeshift value in minutes.</p>
          <input
            className='cs-input col-12'
            placeholder='Enter label here'
            value={timeshiftInMinutes}
            onChange={e => setTimeshift(e.target.value)}/>
          <div className='flex items-center justify-end mt2'>
            <Button
              onClick={() => createChannel({ parent_live_channel_id: channelID, timeshift_in_minutes: timeshiftInMinutes }, res => {
                setShowTimeshiftModal(false)
                history.push(`channels/${res._id}`)
              })}>Create Timeshift Channel</Button>
          </div>
        </div>
      </Modal>}
    </>
  )
}

const mapStateToProps = state => ({
  channels: state.channels.list
})

const mapDispatchToProps = dispatch => ({
  createChannel: bindActionCreators(createChannel, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateChannelButton))