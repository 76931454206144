export const GET_CHANNELS = {
  REQUEST: "GET_CHANNELS",
  PENDING: "GET_CHANNELS_PENDING",
  REJECTED: "GET_CHANNELS_REJECTED",
  FULFILLED: "GET_CHANNELS_FULFILLED"
}

export const GET_CHANNEL = {
  REQUEST: "GET_CHANNEL",
  PENDING: "GET_CHANNEL_PENDING",
  REJECTED: "GET_CHANNEL_REJECTED",
  FULFILLED: "GET_CHANNEL_FULFILLED"
}

export const CREATE_CHANNEL = {
  REQUEST: "CREATE_CHANNEL",
  PENDING: "CREATE_CHANNEL_PENDING",
  REJECTED: "CREATE_CHANNEL_REJECTED",
  FULFILLED: "CREATE_CHANNEL_FULFILLED"
}

export const UPDATE_CHANNEL = {
  REQUEST: "UPDATE_CHANNEL",
  PENDING: "UPDATE_CHANNEL_PENDING",
  REJECTED: "UPDATE_CHANNEL_REJECTED",
  FULFILLED: "UPDATE_CHANNEL_FULFILLED"
}

export const DELETE_CHANNEL = {
  REQUEST: "DELETE_CHANNEL",
  PENDING: "DELETE_CHANNEL_PENDING",
  REJECTED: "DELETE_CHANNEL_REJECTED",
  FULFILLED: "DELETE_CHANNEL_FULFILLED"
}