import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import auth from './auth/reducer'
import messages from './messages/reducer'
import channels from './channels/reducer'
import devices from './devices/reducer'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth,
  messages,
  channels,
  devices
})

export default createRootReducer
