import {
  GrChannel
} from "react-icons/gr"
import {
  FaServer
} from "react-icons/fa"

export const LINKS = [
  {
    text: "Channels",
    to: "/channels",
    icon: <GrChannel size='1.6em'/>,
  },
  {
    text: "Devices",
    to: "/devices",
    icon: <FaServer size='1.5em'/>,
  }
]