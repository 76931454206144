import { useEffect, useState } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Status, Table, Button } from "@bitcine/cs-theme"
import { getChannel } from "../../redux/channels/api"
import { getDevices } from "../../redux/devices/api"
import LivestreamPlayer from "../../components/LivestreamPlayer"
import Options from './options'

const Channel = ({ match, pending, channel, getChannel, devices, getDevices }) => {
  const [viewChannel, setViewChannel] = useState(false)
  const channelID = match.params.channelID
  useEffect(() => {
    getChannel(channelID)
  }, [getChannel, channelID])
  useEffect(() => {
    const interval = setInterval(() => {
      getDevices()
    }, 10000)
    getDevices()
    return () => clearInterval(interval);
  }, [getDevices])
  return (
    <Status pending={pending}>
      <div className='border border-gray-5 p2 mt2 box-shadow bg-white'>
        <div className='flex items-center justify-between'>
          <h3>{channel.label}</h3>
          <div className='flex items-center'>
            <Button
              // disabled={channel.status !== 'Active'} // TODO: this should pull the channel / input active broadcast state
              className='mr1'
              small
              onClick={() => setViewChannel(channel)}>
              View Livestream
            </Button>
            <Options/>
          </div>
        </div>
        <div className='border-top border-bottom border-gray-5 my2 py2'>
          <p>Status: <b>{channel.status}</b></p>
          <p>Allowed IP Addresses: <b>{channel.input_security_group_ip_addresses}</b></p>
          <p>Broadcast URL: <b>{channel.input_broadcast_url}</b></p>
          <p>Output URL: <b>{channel.output_group_url}</b></p>
          <p>Profile URL: <b>{channel.profile_url}</b></p>
        </div>
        <Table
          className='my3'
          widths={["auto", 300]}
          header={{
            columns: [
              { text: "Name" },
              { text: "Status" },
            ],
          }}
          body={{
            data: devices,
            row: {
              compact: true,
              render: [
                data => data.name,
                data => data.now_playing_channel?.label
              ]
            },
            empty: {
              title: "No Devices Attached",
              text: "Add this channel to a device!",
            }
          }}/>
      </div>
      {viewChannel && <LivestreamPlayer channel={viewChannel} onClose={() => setViewChannel(null)}/>}
    </Status>
  )
}

const mapStateToProps = state => ({
  pending: [state.channels.status, state.devices.status].includes("PENDING"),
  channel: state.channels.model,
  devices: state.devices.list
})

const mapDispatchToProps = dispatch => ({
  getChannel: bindActionCreators(getChannel, dispatch),
  getDevices: bindActionCreators(getDevices, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Channel)