export const GET_DEVICES = {
  REQUEST: "GET_DEVICES",
  PENDING: "GET_DEVICES_PENDING",
  REJECTED: "GET_DEVICES_REJECTED",
  FULFILLED: "GET_DEVICES_FULFILLED"
}

export const GET_DEVICE = {
  REQUEST: "GET_DEVICE",
  PENDING: "GET_DEVICE_PENDING",
  REJECTED: "GET_DEVICE_REJECTED",
  FULFILLED: "GET_DEVICE_FULFILLED"
}

export const UPDATE_DEVICE = {
  REQUEST: "UPDATE_DEVICE",
  PENDING: "UPDATE_DEVICE_PENDING",
  REJECTED: "UPDATE_DEVICE_REJECTED",
  FULFILLED: "UPDATE_DEVICE_FULFILLED"
}

export const DELETE_DEVICE = {
  REQUEST: "DELETE_DEVICE",
  PENDING: "DELETE_DEVICE_PENDING",
  REJECTED: "DELETE_DEVICE_REJECTED",
  FULFILLED: "DELETE_DEVICE_FULFILLED"
}

export const PLAY_CHANNELS = {
  REQUEST: "PLAY_CHANNELS",
  PENDING: "PLAY_CHANNELS_PENDING",
  REJECTED: "PLAY_CHANNELS_REJECTED",
  FULFILLED: "PLAY_CHANNELS_FULFILLED"
}