import { 
  GET_DEVICES,
  GET_DEVICE,
  UPDATE_DEVICE,
  DELETE_DEVICE,
  PLAY_CHANNELS
} from './types'
import { get, put, post, del } from '../fetch'
import { addMessage } from "../messages/api"

export const getDevices = () => dispatch => dispatch(
  get(
    GET_DEVICES.REQUEST,
    `live-devices`,
  )
)

export const getDevice = deviceID => dispatch => dispatch(
  get(
    GET_DEVICE.REQUEST,
    `live-devices/${deviceID}`,
  )
)

export const updateDevice = (deviceID, data, callback) => dispatch => dispatch(
  put(
    UPDATE_DEVICE.REQUEST,
    `live-devices/${deviceID}`,
    data,
    callback
  )
)

export const deleteDevice = (deviceID, callback) => dispatch => dispatch(
  del(
    DELETE_DEVICE.REQUEST,
    `live-devices/${deviceID}`,
    null,
    callback
  )
)

export const playChannelsOnDevices = data => dispatch => dispatch(
  post(
    PLAY_CHANNELS.REQUEST,
    `live-devices/play-channels`,
    data,
    () => dispatch(addMessage("Triggered play!"))
  )
)