import {
  GET_CHANNELS,
  GET_CHANNEL,
  CREATE_CHANNEL,
  UPDATE_CHANNEL,
  DELETE_CHANNEL
} from "./types"

const initialState = {
  status: "PENDING",
  list: [],
  model: {}
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_CHANNELS.PENDING:
    case GET_CHANNEL.PENDING:
    case CREATE_CHANNEL.PENDING:
    case DELETE_CHANNEL.PENDING:
    case UPDATE_CHANNEL.PENDING:
      return initialState
    case GET_CHANNELS.FULFILLED:
    case CREATE_CHANNEL.FULFILLED:
    case DELETE_CHANNEL.FULFILLED:
      return {
        ...state,
        status: "READY",
        list: action.payload
      }
    case GET_CHANNEL.FULFILLED:
    case UPDATE_CHANNEL.FULFILLED:
      return {
        ...state,
        status: "READY",
        model: action.payload
      }
    default:
      return state
  }
}