import { ADD_GLOBAL_MESSAGE, REMOVE_GLOBAL_MESSAGE } from './types'

export const addMessage = (message, type = 'success', icon, timeout = 3000, showCloseButton = false) => dispatch => {
  const _id = Math.random().toString(36).substring(7)
  dispatch({
    type: ADD_GLOBAL_MESSAGE,
    payload: {
      _id,
      message,
      icon,
      type,
      timeout,
      showCloseButton,
      onClose: () => dispatch(removeMessage(_id))
    }
  })
  return _id
}

export const removeMessage = _id => dispatch => dispatch({ type: REMOVE_GLOBAL_MESSAGE, _id })