import { useEffect } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Status, Table } from "@bitcine/cs-theme"
import { getChannels } from "../../redux/channels/api"
import CreateChannelButton from "../../components/CreateChannelButton"

const Channels = ({ status, list, getChannels, history }) => {
  useEffect(() => {
    getChannels()
  }, [getChannels])
  return (
    <Status pending={status === "PENDING"}>
      <div className='border border-gray-5 p2 mt2 box-shadow bg-white'>
        <div className='flex items-center justify-between'>
          <h3>Channels</h3>
          <CreateChannelButton/>
        </div>
        <Table
          className='my3'
          widths={[
            "auto",
            200,
            300
          ]}
          header={{
            columns: [
              { text: "Label" },
              { text: "Timeshift" },
              { text: "Status" },
            ],
          }}
          body={{
            data: list,
            row: {
              compact: true,
              onClick: (e, data) => {
                history.push(`channels/${data._id}`)
              },
              render: [
                data => data.label,
                data => data.timeshift_in_minutes ? `${data.timeshift_in_minutes} minutes` : "",
                data => data.status,
              ]
            },
            empty: {
              title: "No Channels",
              text: "Create a new channel to get started!",
            }
          }}/>
      </div>
    </Status>
  )
}

const mapStateToProps = state => ({
  status: state.channels.status,
  list: state.channels.list
})

const mapDispatchToProps = dispatch => ({
  getChannels: bindActionCreators(getChannels, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Channels))