import styled from "styled-components"
import { Modal } from "@bitcine/cs-theme"
import CineSendPlayer from "@bitcine/cinesend-player"

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`

const ButtonContainer = styled.div`
  position: relative;
  top: 32px;
  left: 32px;
  z-index: 10;
`

const LivestreamPlayer = ({ channel, onClose }) =>
  <Modal open width={3} title={`Livestream Preview: ${channel.label}`} onClose={onClose} clickOutsideCloses>
    <Container>
      <ButtonContainer>
        <span className='material-icons white pointer' onClick={onClose}>close</span>
      </ButtonContainer>
      <CineSendPlayer
        playerID={"cinesend-player-id"}
        playerCode={"videojs"}
        onVideoChange={video => console.log("Video set to: ", video)}
        onPlayerCreation={player => this.setState({ player })}
        onPlayerEvent={event => console.log("Event log: ", event)}
        autoPlay={true}
        fillViewport={true}
        debug={false}
        activeIndex={0}
        endpoints={{
          preroll: [],
          videos: [
            {
              source: {
                hls: channel.output_group_url
              }
            }
          ]
        }}
      />
    </Container>
  </Modal>

export default LivestreamPlayer