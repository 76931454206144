import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { ButtonDropdown } from "@bitcine/cs-theme"
import AppSwitcher from "@bitcine/cinesend-app-switcher"
import styled from "styled-components"
import Logo from "../../media/logo.inline.svg"
import { logOut } from '../../redux/auth/api'

const Bar = styled.div`
  position: fixed;
  height: 56px;
  width: 100%;
  box-shadow: 0 1px 8px 0 rgba(146, 153, 159, 0.21);
`

const HeaderBar = ({ apps, name, logOut }) => {
  return (
    <Bar className='bg-white relative flex items-center justify-between px3 z1'>
      <div className='flex items-center' style={{ marginLeft: "-12px" }}>
        <AppSwitcher
          apps={Object.keys(apps)
            .filter(key => !apps[key].hide)
            .reduce((all, key) => ({ ...all, [key]: apps[key] }), {})}
          activeApplicationKey='live'/>
        <Link to='/' className='flex items-center ml2'>
          <img src={Logo} width={"100px"} alt="CineSend Live"/>
          <strong className='ml2'>Live</strong>
        </Link>
      </div>
      <div className='flex items-center'>
        <ButtonDropdown
          button={{
            className: "link",
            text: (
              <div className='flex items-center'>
                {name}
                <span className='material-icons ml2'>keyboard_arrow_down</span>
              </div>
            )
          }}
          dropdown={{
            content: [
              {
                text: "Log Out",
                icon: "exit_to_app",
                onClick: () => logOut()
              }
            ]
          }}/>
      </div>
    </Bar>
  )
}

HeaderBar.defaultProps = {
  apps: {},
  name: ""
}

HeaderBar.propTypes = {
  apps: PropTypes.object,
  name: PropTypes.string,
  logOut: PropTypes.func
}

const mapStateToProps = state => ({
  apps: state.auth.user.app_permissions,
  name: state.auth.user.full_name
})

const mapDispatchToProps = dispatch => ({
  logOut: bindActionCreators(logOut, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBar)
