import { ADD_GLOBAL_MESSAGE, REMOVE_GLOBAL_MESSAGE } from "./types"

const initialState = []

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_GLOBAL_MESSAGE:
      return [...state, action.payload]
    case REMOVE_GLOBAL_MESSAGE:
      return state.filter(msg => msg._id !== action._id)
    default:
      return state
  }
}