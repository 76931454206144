import { useEffect, useState } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Status, Table, Button } from "@bitcine/cs-theme"
import { getDevice, playChannelsOnDevices } from "../../redux/devices/api"
import { getChannels } from "../../redux/channels/api"
import LivestreamPlayer from "../../components/LivestreamPlayer"

const Device = ({ match, pending, device, getDevice, channels, getChannels, playChannelsOnDevices, history }) => {
  const [viewChannel, setViewChannel] = useState(false)
  const deviceID = match.params.deviceID
  useEffect(() => {
    const interval = setInterval(() => {
      getDevice(deviceID)
    }, 10000)
    getDevice(deviceID)
    return () => clearInterval(interval);
  }, [getDevice, deviceID])
  useEffect(() => {
    getChannels()
  }, [getChannels])
  return (
    <Status pending={pending}>
      <div className='border border-gray-5 p2 mt2 box-shadow bg-white'>
        <div className='flex items-center justify-between'>
          <h3>{device.name}</h3>
          <Button
            small
            disabled={!device.now_playing_channel}
            onClick={() => setViewChannel(device.now_playing_channel)}>
            View Active Livestream
          </Button>
        </div>
        <div className='border-top border-bottom border-gray-5 my2 py2'>
          <p>Playback Status: <b>{device.playback_status}</b></p>
          {device.now_playing_channel && <p>Now Playing: <b>{device.now_playing_channel.label}</b></p>}
          <p>IP Address: <b>{device.decoder_ip_address}</b></p>
        </div>
        <Table
          className='my3'
          widths={[80, "auto", 200, 200]}
          header={{
            columns: [
              { text: "" },
              { text: "Label" },
              { text: "Status" },
              { text: "" }
            ],
          }}
          body={{
            data: channels,
            row: {
              compact: true,
              onClick: (e, data) => {
                history.push(`/channels/${data._id}`)
              },
              render: [
                data => data.is_live ? 'Live' : 'Not Live',
                data => data.label,
                data => data.label === device.now_playing_channel?.label
                  ? 'Attached'
                  : 'Not Attached',
                data =>
                  <Button
                    disabled={data.is_playing || data.is_pending_play}
                    link
                    small
                    white
                    onClick={e => {
                      e.stopPropagation()
                      e.preventDefault()
                      playChannelsOnDevices({ channelIDs: [data._id], deviceIDs: [device._id]})
                    }}>
                    Play Livestream
                  </Button>
              ]
            }
          }}/>
      </div>
      {viewChannel && <LivestreamPlayer channel={viewChannel} onClose={() => setViewChannel(null)}/>}
    </Status>
  )
}

const mapStateToProps = state => ({
  pending: [state.channels.status, state.devices.status].includes("PENDING"),
  device: state.devices.model,
  channels: state.channels.list
})

const mapDispatchToProps = dispatch => ({
  getDevice: bindActionCreators(getDevice, dispatch),
  playChannelsOnDevices: bindActionCreators(playChannelsOnDevices, dispatch),
  getChannels: bindActionCreators(getChannels, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Device))