import { LOG_OUT, AUTH_CHECK } from './types'
import { GLOBALS } from '../../config'
import { get, post } from '../fetch'

export const authCheck = () => dispatch => {
  const callbackURL = GLOBALS.URL + window.location.pathname
  dispatch(
    get(
      AUTH_CHECK.REQUEST,
      `auth/check`,
      null,
      error => {
         window.location.replace(`${GLOBALS.ACCOUNT_REDIRECT_URL}?redirect=${callbackURL}`)
      }
    )
  )
}

export const logOut = () => dispatch => {
  dispatch(
    post(
      LOG_OUT,
      `auth/logout`,
      null,
      () => window.location.replace(`${GLOBALS.ACCOUNT_REDIRECT_URL}?redirect=${GLOBALS.URL}`)
    )
  )
}