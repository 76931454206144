import { useEffect, useState } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Status, Table } from "@bitcine/cs-theme"
import { getDevices } from "../../redux/devices/api"
import PlayChannelOnDevicesButton from "../../components/PlayChannelOnDevicesButton"

const Devices = ({ status, list, getDevices, history }) => {
  const [selectedDeviceIDs, setSelectedDeviceIDs] = useState([])
  useEffect(() => {
    const interval = setInterval(() => {
      getDevices()
    }, 10000)
    getDevices()
    return () => clearInterval(interval);
  }, [getDevices])
  return (
    <Status pending={status === "PENDING"}>
      <div className='border border-gray-5 p2 mt2 box-shadow bg-white'>
        <div className='flex items-center justify-between'>
          <h3>Live Stream Devices</h3>
          <PlayChannelOnDevicesButton deviceIDs={selectedDeviceIDs} clearSelectedIDs={() => setSelectedDeviceIDs([])}/>
        </div>
        <Table
          className='my3'
          widths={["auto", 300]}
          header={{
            columns: [
              { text: "Name" },
              { text: "Now Playing" },
            ],
            checkbox: {
              checked: selectedDeviceIDs.length === list.length,
              intermediate: false,
              onChange: (value) =>
                selectedDeviceIDs.length === list.length
                  ? setSelectedDeviceIDs([])
                  : setSelectedDeviceIDs(list.map((video) => video._id)),
            },
          }}
          body={{
            data: list,
            row: {
              compact: true,
              checkbox: {
                checked: (data) => selectedDeviceIDs.includes(data._id),
                onChange: (data) =>
                  selectedDeviceIDs.includes(data._id)
                    ? setSelectedDeviceIDs(selectedDeviceIDs.filter((id) => id !== data._id))
                    : setSelectedDeviceIDs([...selectedDeviceIDs, data._id]),
              },
              onClick: (e, data) => {
                history.push(`devices/${data._id}`)
              },
              render: [
                data => data.name,
                data => data.is_offline ? 'Offline' : data.now_playing_channel ? data.now_playing_channel.label : '--',
              ]
            }
          }}/>
      </div>
    </Status>
  )
}

const mapStateToProps = state => ({
  status: state.devices.status,
  list: state.devices.list
})

const mapDispatchToProps = dispatch => ({
  getDevices: bindActionCreators(getDevices, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Devices))